import React from "react"
import cmptStyles from "../styles/article.module.css"
import { graphql } from "gatsby"
import RichText from "./shared/richtext"
import { ContentfulImage } from "./shared/image"
import { AutoLink } from "./shared/link"

const RelatedArticle = ({ 
    content: { 
        title, 
        route, 
        category, 
        publishedDate, 
        author: {
            name
        }, 
        image 
    }, 
    authoredByPrefix
}) => {
    return (
        <aside className={ cmptStyles.relatedArticle }>
            <AutoLink uri={ route }>
                <div className={ cmptStyles.relatedArticleImage }>
                    <figure>
                        <ContentfulImage image={ image } />
                    </figure>
                </div>
                <div className={ cmptStyles.relatedArticleText }>
                    <span className={ cmptStyles.relatedArticleCategory }>{ category }</span>
                    <h3 className={ cmptStyles.relatedArticleTitle }>{ title }</h3>
                    <p className={ cmptStyles.relatedArticleAuthoredBy }>{ authoredByPrefix } { name } | { publishedDate }</p>
                </div>
            </AutoLink>
        </aside>
    )
}

const Article = ({ 
    content: { 
        title,
        category,
        publishedDate,
        author: {
            name
        },
        image,
        post,
        relatedArticles
    }, 
    siteFurniture: {
        authoredByPrefix,
        relatedArticlesHeading
    }
}) => {
    return (
        <section className={ cmptStyles.wrapper } id="post">
            <div className={ cmptStyles.container }>
                <div className={ cmptStyles.inner }>

                    <div className={ cmptStyles.hero }>
                
                        <div className={ cmptStyles.image }>
                            <figure>
                                <ContentfulImage image={ image } />
                            </figure>
                        </div>

                        <div className={ cmptStyles.heroText }>
                            <span className={ cmptStyles.category }>{ category }</span>
                            <h1 className={ cmptStyles.title }>{ title }</h1>
                            <p className={ cmptStyles.authoredBy }>{ authoredByPrefix } { name } | { publishedDate }</p>
                        </div>

                    </div>

                    <div className={ cmptStyles.text }>
                        <RichText content={ post }/>
                    </div>

                    {
                        relatedArticles && <div className={ cmptStyles.relatedArticles }>
                            <h2 className={ cmptStyles.relatedArticlesHeading }>{ relatedArticlesHeading }</h2>
                            <div className={ cmptStyles.relatedArticlesList }>
                            { relatedArticles.map( article => {
                                return (
                                    <RelatedArticle content={ article } authoredByPrefix={ authoredByPrefix } key={ article.title }/>
                                )
                            }) }
                            </div>
                        </div>
                    }

                </div>
            </div>
        </section>
    )
}

export default Article

export const articleQuery = graphql`
    fragment Article on ContentfulPageArticle {
        id
        node_locale
        route
        title
        category
        publishedDate(formatString: "ddd MMM DD YYYY")
        author {
            name
        }
        image {
            ...ContentfulImageProperties
        }
        post {
            json
        }
        relatedArticles {
            title
            route
            category
            publishedDate(formatString: "ddd MMM DD YYYY")
            author {
                name
            }
            image {
                ...ContentfulImageProperties
            }
        }
        seoTitle
        seoDescription {
            seoDescription
        }
        seoImage {
            fixed(quality: 80, width: 1920, resizingBehavior: SCALE) {
                src
            }
            description
        }
    }
`