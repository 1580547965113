import React from "react"
import { graphql } from "gatsby"

import Metadata from "../components/shared/metadata"
import Header from "../components/header"
import Article from "../components/article"
import Footer from "../components/footer"
import Cookies from "../components/shared/cookies"
import Support from "../components/shared/support"

const Page = ({ 
    data: {
        header,
        content,
        footer,
        siteFurniture: {
            siteFurniture
        },
        site: {
            siteMetadata
        },
        localMetadata: {
            localMetadata
        }
    } 
}) => {
    return (
        <>
            <Metadata content={ content } siteMetadata={ siteMetadata } localMetadata={ localMetadata } isAmp="false" />
            <Header content={ header } siteFurniture={ siteFurniture } />
            <main role="main" id="#main-content">
                <Article content={ content } siteFurniture={ siteFurniture } isAmp="false" />
            </main>
            <Footer content={ footer } siteFurniture={ siteFurniture } />
            <Cookies/>
            <Support/>
        </>
    )
}

export default Page

// eslint can't handle variables used in child fragments...
/* eslint-disable */
export const pageQuery = graphql`
    query($id: String!) {
        header: dataJson {
            ...Header
        }
        content: contentfulPageArticle( id: { eq: $id } ) {
            ...Article
        }
        footer: dataJson {
            ...Footer
        }
        siteFurniture: dataJson {
            ...SiteFurniture
        }
        ...EnvSiteMetadata
        localMetadata: dataJson {
            ...LocalMetadata
        }
    }
`